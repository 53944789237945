@import "@/styles/mixins";

:import("@/components/ui/Buttons/styles.module.scss") {
  buttons: buttons;
}

.callout {
  @include default-margin;
  flex: 0 0 auto !important; position: relative; z-index: 0; overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05); padding: var(--gridunit-small); border-radius: var(--radius-default); border-left: 6px solid currentColor;
  display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; gap: var(--gridunit-small);


  &:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: currentColor; opacity: 0.2; z-index: -1; }

  &:after { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: var(--color-white); opacity: 1; z-index: -2; }

  .buttons { flex: 0 0 auto; margin: 0 !important; gap: var(--gridunit-tiny); }

  A { color: currentColor; text-decoration: underline; }

  &__icon {
    flex: 0 0 auto; position: relative; display: flex; align-items: center; justify-content: center;

    path, circle { fill: currentColor; }
  }

  &__text { flex: 1 1 auto; }

  &__title { font-weight: var(--bold); display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small); align-items: center; }

  &__description {
    margin-top: 0; font-size: 13px;;

    P:first-child { margin-top: 0; }
  }

  // Intents

  &.intent-danger { color: var(--color-danger); border-color: var(--color-danger); }

  &.intent-warning { border-color: var(--color-warning); color: var(--color-warning); }

  &.intent-success { color: var(--color-success); border-color: var(--color-success); }

  & + & { margin-top: var(--gridunit-tiny); }
}
