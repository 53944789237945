@import "@/styles/mixins";

.control-checkbox {
	flex: 0 0 auto; position: relative; display: inline-block; width: 16px; height: 16px; z-index: 0;

	&:before { content: ""; position: absolute; left: -10px; top: -10px; right: -10px; bottom: -10px; background-color: transparent; }

	&__overflow {
		position: absolute; left: 0; right: 0; top: 0; bottom: 0;

		INPUT {
			position: absolute; right: 100%; bottom: 100%; pointer-events: none; opacity: 0;

			&:checked + SPAN {
				background-color: currentColor;

				svg { opacity: 1; }

				path { fill: var(--color-white); }
			}

			&:disabled + SPAN,
			&[disabled] + SPAN {
				opacity: 0.5;
			}

			&:focus + SPAN {
				&:before { box-shadow: inset 0 0 1px currentColor; }

				&:after { opacity: 0.2; }
			}
		}

		SPAN {
			position: absolute; left: 0; right: 0; top: 0; bottom: 0; transition: all var(--ui-speed) var(--ui-easing) 0ms; border-radius: 1px;

			svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); opacity: 0; margin: 0 !important; }

			path { fill: currentColor; }

			&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: inset 0 0 0 1px currentColor; border-radius: 2px; opacity: 0.3; pointer-events: none; }

			&:after {
				content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0;
				box-shadow: 0 0 0 4px var(--color-focus); border-radius: 2px; opacity: 0; pointer-events: none; transition: opacity var(--ui-speed) var(--ui-easing) 0ms;
			}

			@media print { border: 1px solid currentColor !important; }
		}

		&.has-error {
			SPAN {
				&:before { box-shadow: inset 0 0 0 1px var(--color-danger); opacity: 1; }

				path { fill: var(--color-danger); }
			}

			INPUT:checked + SPAN {
				&:before { background-color: var(--color-danger); }
			}
		}
	}
}
