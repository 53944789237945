@import 'reset';
@import 'typography';
@import 'basics';
@import 'helpers';
@import 'content';

:root {
  // Theme marker
  --theme: 'default';

  // Base colors
  --color-white: #ffffff;
  --color-black: #000000;

  // Primary color
  --color-primary-50: #E6F0FE;
  --color-primary-100: #CEE1FD;
  --color-primary-200: #9DC4FB;
  --color-primary-300: #6CA7F9;
  --color-primary-400: #3B89F6;
  --color-primary-500: #0B6CF4;
  --color-primary-600: #0856C3;
  --color-primary-700: #1E4691;
  --color-primary-800: #042B62;
  --color-primary-850: #032049;
  --color-primary-900: #021631;

  // Neutral color
  --color-neutral-50: #F2F2F3;
  --color-neutral-100: #E4E5E7;
  --color-neutral-200: #C9CBCF;
  --color-neutral-300: #AEB1B7;
  --color-neutral-400: #93979F;
  --color-neutral-500: #787D87;
  --color-neutral-600: #60646C;
  --color-neutral-700: #484B51;
  --color-neutral-800: #303236;
  --color-neutral-900: #18191B;

  // Alert color
  --color-alert-200: #FBA39D;
  --color-alert-400: #F7473B;
  --color-alert-500: #D61E1F;
  --color-alert-600: #C41408;
  --color-alert-800: #620A04;

  // Success color
  --color-success-200: #9DFBA3;
  --color-success-400: #3BF747;
  --color-success-600: #08C414;
  --color-success-800: #04620A;

  // Warning color
  --color-warning-200: #fbf39d;
  --color-warning-400: #f7ee3b;
  --color-warning-600: #c4bb08;
  --color-warning-800: #624f04;

  // Other colors
  --color-focus: var(--color-primary-500);
  --color-danger: var(--color-alert-400);
  --color-success: var(--color-success-600);
  --color-warning: var(--color-warning-800);

  // Color mapper
  --site-bg: var(--color-white);
  --site-bg-original: var(--color-white);
  --color-primary: var(--color-primary-500);
  --color-secondary: var(--color-primary-900);
  --color-text: var(--color-primary-900);

  --header-bg: var(--color-primary-850);
  --header-color: var(--color-white);
  --header-secondary: var(--color-secondary);
  --footer-bg: var(--color-primary-850);
  --footer-color: var(--color-white);
  --backdrop-bg: var(--color-neutral-800);
  --button-bg: var(--color-primary);
  --button-color: var(--color-white);

  --badge-default-bg: var(--color-primary-600);
  --badge-default-color: var(--color-white);
  --badge-discrete-bg: var(--color-neutral-200);
  --badge-discrete-color: var(--color-primary-900);

  --color-link: var(--color-primary-500);
  --color-link-hover: var(--color-primary-700);
  --color-highlight: var(--color-primary-500);

  // Size units
  --content-maxwidth: 1280px;
  --radius-small: 3px;
  --radius-default: 6px;
  --radius-large: 12px;
  --gridunit: 24px;
  --gridunit-small: 12px;
  --gridunit-tiny: 6px;

  // Animations
  --ui-speed: 250ms;
  --ui-easing: cubic-bezier(0.42, 0, 0.58, 1);

  // Typography
  --font-size-largest: 32px;
  --font-size-larger: 28px;
  --font-size-large: 24px;
  --font-size-medium: 20px;
  --font-size-default: 16px;
  --font-size-small: 14px;
  --font-size-smaller: 12px;
  --font-size-smallest: 10px;
  --line-height-default: 1.42;
  --line-height-headings: 1.33;
  --medium: 600;
  --bold: 800;
  --heavy: 800;

  @include mobile {
    --margin-horizontal: 12px;
    --margin-vertical: 24px;
    --padding-horizontal: 12px;
    --padding-vertical: 24px;

    --font-size-largest: 42px;
    --font-size-larger: 28px;
    --font-size-large: 24px;
    --font-size-medium: 20px;
  }

  @include tablet {
    --margin-horizontal: 48px;
    --margin-vertical: 40px;
    --padding-horizontal: 32px;
    --padding-vertical: 24px;
  }

  @include desktop {
    --margin-horizontal: 24px;
    --margin-vertical: 32px;
    --padding-horizontal: 32px;
    --padding-vertical: 24px;
  }
}


HTML, BODY {
  background-color: var(--site-bg);
  padding: 0;
  margin: 0;
}

#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: relative;
}
