@import "@/styles/mixins";

.well {
  flex: 1 0 auto; border-radius: var(--radius-large); @include default-margin; position: relative;

  &.theme-default {
    box-shadow: inset 0 0 0 1px var(--color-neutral-300);
  }

  &.theme-light {
    color: var(--color-text);
    background-color: var(--color-neutral-50);
  }

  &.theme-lightest {
    --site-bg: var(--color-white);
    background-color: var(--color-white);

    @include mobile { margin-left: calc(var(--gridunit-small) * -1); margin-right: calc(var(--gridunit-small) * -1); }
  }

  &.theme-dark {
    --site-bg: var(--color-primary-900);
    --color-highlight: var(--color-primary-50);
    background-color: var(--color-primary-900);
    color: var(--color-white);
  }

  > * { margin-top: 0 !important; }

  &__wrapper {
    @include default-padding; gap: var(--gridunit); display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;

    @include mobile { display: block; }
  }

  &__content {
    grid-area: content; flex: 1 1 auto;

    > *:first-child {
      @include default-margin;
    }

    .well__content { @include default-padding; }
  }

  &__title { grid-area: title; margin-top: var(--gridunit); }

  &:has(&__title):has(&__animation-left) & {
    &__wrapper {
      padding: calc(var(--gridunit-large) - var(--gridunit)) var(--gridunit) var(--gridunit-large) var(--gridunit);
      display: grid; grid-template-areas: "title content" "animation content"; grid-template-columns: 1fr 1fr; grid-template-rows: 1fr auto; align-items: stretch;
    }

    &__animation-left {
      position: relative; align-self: end;

      > * { position: absolute; left: 30%; top: 50%; margin: 0; transform: translate(0, -50%); }
    }

    @include mobile {
      &__wrapper { grid-template-areas: "animation" "title" "content"; grid-template-columns: 1fr; grid-template-rows: auto; gap: 0; padding: 0 var(--gridunit-small) var(--gridunit) var(--gridunit-small); }

      &__animation-left {
        height: 10px;

        > * { left: auto; right: -20px; top: auto; bottom: -40px; transform: none; }
      }
    }

    @include tablet {
      &__wrapper { grid-template-areas: "title title" "animation content"; grid-template-columns: 200px 1fr; grid-template-rows: auto 1fr; }

      &__animation-left {
        > * { left: 0; }
      }
    }
  }
}
