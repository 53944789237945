@import "@/styles/mixins";

:import("@/components/ui/Button/styles.module.scss") {
	button: button;
}

.form-row {
	display: flex; flex-direction: column; gap: 4px; text-align: left;

	@include mobile {
		grid-column: span 2;
	}

	@include not-mobile {
		&.size-default { grid-column: span 2; }

		&.size-narrow { grid-column: auto; }
	}

	&__label {
		flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit); color: var(--color-neutral-600);

		LABEL { flex: 1 1 auto; text-overflow: ellipsis; word-break: break-all; display: block; }

		&__required { color: var(--color-danger); }
	}

	&__content {
		flex: 1 0 auto; display: flex; flex-direction: column; gap: 4px;

		&__items {
			display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-tiny);

			> * { flex: 1 1 auto; }

			.button { flex: 0 0 auto; }
		}

		&__description { text-align: right; }
	}

	&__value { padding: 0; }
}
