@import "@/styles/mixins";

.checkbox-with-label {
	display: flex; align-items: center; gap: var(--gridunit-tiny); user-select: none; cursor: default; box-sizing: border-box;

	&__label {
		flex: 1 1 auto; display: flex; flex-direction: column; overflow: hidden; text-overflow: ellipsis;

		&__text {
			&.has-error {
				color: var(--color-danger);

				A { color: var(--color-danger); }
			}
		}
	}
}
