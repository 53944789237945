@import "@/styles/mixins";

.control-input {
  $element-height: 36px;

  display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; cursor: text; position: relative; background-color: var(--color-white);

  &:before {
    content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: inset 0 0 0 1px currentColor; border-radius: var(--radius-default); opacity: 0.3; pointer-events: none;
    transition: opacity var(--ui-speed) var(--ui-easing) 0ms;
  }

  &:after {
    content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0;
    box-shadow: 0 0 0 4px var(--color-focus); border-radius: var(--radius-default); opacity: 0; pointer-events: none; transition: opacity var(--ui-speed) var(--ui-easing) 0ms;
  }

  &.focus {
    &:before { opacity: 1; box-shadow: inset 0 0 0 1px var(--color-focus); }

    &:after { opacity: 0.2; }
  }

  &:hover:before { opacity: 1; }

  &.disabled,
  &.readonly {
    cursor: default;

    &:before { box-shadow: none; background-color: currentColor; opacity: 0.05; }
  }

  &.has-error {
    &:before { box-shadow: inset 0 0 0 1px var(--color-danger); }
  }

  &.has-value {
    .control-input__addon { color: currentColor; }
  }

  &__holder { flex: 1 1 auto; }

  &__input {
    width: 100%; margin: 0; padding: 0; font-size: inherit; background-color: transparent; border: none; box-shadow: none; border-radius: 0; outline: none; appearance: none; height: $element-height; line-height: $element-height; text-align: inherit; flex: 1 1 auto;

    &::placeholder { color: rgba(0, 0, 0, 0.3); }

    &::-ms-clear { display: none; appearance: none; width: 0; height: 0; opacity: 0; }

    &[value=""] ~ .control-input__addon { color: currentColor; }
  }

  &__holder:first-child &__input { padding-left: 12px; }

  &__holder:last-child &__input { padding-right: 12px; }

  &__icon {
    padding: 0; display: flex; align-items: center; justify-content: center; width: $element-height; box-sizing: border-box; color: inherit; flex: 0 0 auto;

    path { fill: currentColor; }
  }

  &__addon,
  &__addon-front {
    display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch;
    flex: 0 0 auto; pointer-events: none; user-select: none; position: relative; line-height: $element-height; min-width: $element-height;

    > SPAN { padding: 0 var(--gridunit-small); }

    A,
    BUTTON {
      flex: 0 0 auto; background: transparent; border: none; outline: none; border-radius: 0; padding: 0; margin: 0; position: relative; min-width: $element-height; color: currentColor;
      cursor: pointer; pointer-events: auto;

      &[disabled],
      &:disabled {
        pointer-events: none;

        path, circle { fill: currentColor; }
      }

      &:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; }

      svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
    }

    path, circle { fill: currentColor; stroke: currentColor; }

    SPAN { white-space: nowrap; }

    > svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
  }

  &__addon { padding: 0; align-items: center; justify-content: center; }

  &__addon-front { padding: 0; }

  &__loader {
    display: flex; flex-direction: row; flex-wrap: nowrap;
    flex: 0 0 auto; pointer-events: none; user-select: none; position: relative; line-height: $element-height; min-width: $element-height;
    padding: 0; align-items: center; justify-content: center;
  }

  &__drop {
    position: absolute; left: 0; right: 0; top: 100%; background-color: var(--color-white); color: var(--color-text); z-index: 20;
    box-shadow: 2px 2px 28px 0 rgba(49, 45, 42, 0.1); pointer-events: none; opacity: 0; transform: translate(0, -4px);
    transition: all var(--ui-easing) var(--ui-speed); max-height: 300px; overflow: hidden; overflow-y: auto; @include scrollbars;

    &.open { display: block; opacity: 1; pointer-events: auto; transform: translate(0, 0); }

    BUTTON {
      text-align: left; display: block; appearance: none; border: none; padding: 0; margin: 0; cursor: pointer; box-shadow: none; background: transparent; color: inherit; width: 100%; overflow: hidden; font-size: 14px; line-height: 24px;

      path { fill: currentColor; }

      &:focus,
      &:hover { color: var(--color-link-hover); background-color: var(--color-neutral-100); }

      &.selected { color: var(--color-link-hover); background-color: var(--color-neutral-100); }
    }

    &__inner {
      padding: 8px 12px; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; gap: var(--gridunit-tiny);

      > DIV { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }
    }

    & > LI:first-child &__inner { padding-top: 10px; }

    & > LI:last-child &__inner { padding-bottom: 10px; }
  }

  @at-root TD:has(&) {
    padding-top: 2px; padding-bottom: 2px;
  }

  @media print { display: none; }
}
