@import "@/styles/mixins";

:import("@/components/ui/Scrollable/styles.module.scss") {
  scrollable: scrollable;
  scrollable__scroller: scrollable__scroller;
}

.block {
  flex: 1 0 auto;
  padding: 0 0 var(--padding-vertical) 0;

  display: grid; gap: 0; align-content: start; grid-auto-rows: min-content;
  grid-template-columns: [max-start] var(--padding-horizontal) [full-start] 1fr [limited-start leftinner-start] minmax(0, 592px) [leftinner-end] var(--padding-horizontal) [rightinner-start] minmax(0, 592px) [rightinner-end limited-end] 1fr [full-end] var(--padding-horizontal) [max-end];

  > * {
    grid-column: limited;
  }

  .breakout {
    padding: 0 !important;
    grid-column: max;
    box-sizing: border-box;

    @include not-mobile {
      TABLE {
        width: unset !important;
      }
    }

    &.width-max {
      max-width: none;
      grid-column: max;
    }

    &.width-full {
      max-width: none;
      grid-column: full;
    }

    &.width-default {
      width: 100%;
      max-width: var(--content-maxwidth);
      grid-column: full;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }

    &.width-medium {
      width: 100%;
      max-width: 1016px;
      grid-column: full;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;

      @include mobile {
        margin: 0;
      }
    }

    &.width-narrow {
      width: 100%;
      max-width: 600px;
      grid-column: full;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;

      @include mobile {
        margin: 0;
        max-width: 100%;
      }
    }

    @include not-mobile {
      .scrollable,
      .scrollable__scroller {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  @supports (padding: max(0px)) {
    padding-left: max(0, #{'env(safe-area-inset-left)'});
    padding-right: max(0, #{'env(safe-area-inset-right)'});
  }

  // Themes

  &.theme-default {
    background-color: transparent;
  }

  &.theme-lightest {
    --site-bg: var(--color-white);
    background-color: var(--color-white);
  }

  &.theme-lighter {
    background: var(--color-neutral-50);
  }

  &.theme-light {
    --site-bg: var(--color-primary-300);
    background-color: var(--color-primary-300);
    color: var(--color-white);
  }

  &.theme-medium {
    --site-bg: var(--color-primary-500);
    background-color: var(--color-primary-500);
    color: var(--color-white);
  }

  &.theme-dark {
    --site-bg: var(--color-primary-850);
    background-color: var(--color-primary-850);
    color: var(--color-white);
  }

  // Widths

  &.width-default {
    @include not-mobile {
      grid-template-columns: [max-start] var(--padding-horizontal) [full-start] 1fr [limited-start leftinner-start] minmax(0, 592px) [leftinner-end] var(--padding-horizontal) [rightinner-start] minmax(0, 592px) [rightinner-end limited-end] 1fr [full-end] var(--padding-horizontal) [max-end];
    }
  }

  &.width-medium {
    @include not-mobile {
      grid-template-columns: [max-start] var(--padding-horizontal) [full-start] 1fr [limited-start leftinner-start] minmax(0, 400px) [leftinner-end] var(--padding-horizontal) [rightinner-start] minmax(0, 400px) [rightinner-end limited-end] 1fr [full-end] var(--padding-horizontal) [max-end];
    }
  }

  &.width-narrow {
    @include not-mobile {
      padding: calc(var(--padding-horizontal) - 1em) 0 var(--padding-horizontal) 0 !important;
      grid-template-columns: [max-start] var(--padding-horizontal) [full-start] 1fr [limited-start leftinner-start] minmax(0, 300px) [leftinner-end] var(--padding-horizontal) [rightinner-start] minmax(0, 300px) [rightinner-end limited-end] 1fr [full-end] var(--padding-horizontal) [max-end];
    }
  }
}