@import "@/styles/mixins";

.scrollable {
  position: relative; z-index: 0; display: flex;

  &__scroller {
    flex: 1 1 auto; position: relative; overflow: hidden; -ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto; user-select: none;

    &.dragging * { pointer-events: none; }

    &.noscroll {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  &__sentinel-left {
    position: relative; flex: 0 0 var(--gridunit); pointer-events: none;
  }

  &__sentinel-right {
    position: relative; flex: 0 0 var(--gridunit); pointer-events: none;
  }

  &__shadow-left {
    position: sticky; opacity: 0; pointer-events: none; transition: opacity var(--ui-speed) var(--ui-easing) 0ms; z-index: 2;

    &:before { content: ""; position: absolute; }

    &.visible { opacity: 1; }
  }

  &__shadow-right {
    position: sticky; opacity: 0; pointer-events: none; transition: opacity var(--ui-speed) var(--ui-easing) 0ms; z-index: 2;

    &:before { content: ""; position: absolute; }

    &.visible { opacity: 1; }
  }

  &.noshadows &__shadow-left,
  &.noshadows &__shadow-right { opacity: 0 !important; }

  &__arrow {
    cursor: pointer; color: currentColor; display: flex; align-items: center; justify-content: center;
    position: absolute; top: 0; bottom: 0; width: 64px;

    path { fill: currentColor; }

    &:hover { opacity: 0.7; }

    @include mobile { display: none; }

    &__left { right: 100%; }

    &__right { left: 100%; }

    @include not-desktop { display: none; }
  }

  &.horizontal {
    flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: flex-start;

    TABLE { white-space: nowrap; }

    @include mobile {
      margin-left: calc(var(--gridunit-small) * -1); margin-right: calc(var(--gridunit-small) * -1);
    }
  }

  &.horizontal & {
    &__scroller {
      width: 100%; overflow-x: auto; display: flex; flex-direction: row; flex-wrap: nowrap;
    }

    &__sentinel-left {
      width: var(--gridunit); margin-right: calc(var(--gridunit) * -1);
    }

    &__sentinel-right {
      width: var(--gridunit); margin-left: calc(var(--gridunit) * -1);
    }

    &__shadow-left {
      left: 0; top: 0; bottom: 0;

      &:before { width: var(--gridunit); left: 0; top: 0; bottom: 0; background: linear-gradient(to right, var(--site-bg), transparent); }
    }

    &__shadow-right {
      right: 0; top: 0; bottom: 0;

      &:before { width: var(--gridunit); right: 0; top: 0; bottom: 0; background: linear-gradient(to left, var(--site-bg), transparent); }
    }

    @include mobile {
      &__scroller { padding-left: var(--gridunit-small); padding-right: var(--gridunit-small); }

      &__shadow-left { left: calc(var(--gridunit-small) * -1); }

      &__shadow-right { right: calc(var(--gridunit-small) * -1); }
    }
  }

  &.vertical {
    flex-direction: column; height: 100%;
  }

  &.vertical & {
    &__scroller {
      height: 100%; overflow-y: auto; display: flex; flex-direction: column;

      > * { margin-top: 0; }
    }

    &__sentinel-left {
      height: var(--gridunit); margin-bottom: calc(var(--gridunit) * -1);
    }

    &__sentinel-right {
      height: var(--gridunit); margin-top: calc(var(--gridunit) * -1);
    }

    &__shadow-left {
      left: 0; top: 0; right: 0;

      &:before { height: var(--gridunit); left: 0; top: 0; right: 0; background: linear-gradient(to bottom, var(--site-bg), transparent); }
    }

    &__shadow-right {
      left: 0; bottom: 0; right: 0;

      &:before { height: var(--gridunit); left: 0; bottom: 0; right: 0; background: linear-gradient(to top, var(--site-bg), transparent); }
    }
  }
}
