.control-select {
  position: relative;
  width: 100%;
  cursor: pointer;

  &__holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 12px;
    transition: border 0.3s;

    &:hover {
      border-color: #888;
    }
  }

  &__icon {
    margin-right: 8px;
  }

  &__selected {
    flex-grow: 1;
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  &__search {
    width: calc(100% - 24px);
    padding: 8px 12px;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    outline: none;
    box-sizing: border-box;
  }

  &__options {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 3px;
    }
  }

  &__option {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: #f5f5f5;
    }
  }

  &__no-results {
    padding: 8px 12px;
    font-size: 14px;
    color: #888;
    text-align: center;
  }

  &__loader {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  &.has-error .control-select__holder {
    border-color: red;
  }

  &.has-value .control-select__holder {
    border-color: #666;
  }

  &.disabled {
    cursor: not-allowed;

    .control-select__holder {
      background: #f5f5f5;
      border-color: #ddd;
    }
  }
}
