:import("@/components/ui/LoadingContent/styles.module.scss") {
	loading-content: loading-content;
	loading-content__gradient: loading-content__gradient;
	loading-content__fill: loading-content__fill;
	loading-content__circle: loading-content__circle;
	loading-content__elem: loading-content__elem;
	loading-content__group: loading-content__group;
}

.button {
	$h-default: 36px;
	$h-small: 24px;
	$h-large: 48px;

	display: inline-block; border: none; cursor: pointer; text-decoration: none; text-align: center; margin: 0; background-color: transparent;
	white-space: nowrap; position: relative; transition: all var(--ui-speed) var(--ui-easing) 0ms; box-sizing: border-box; user-select: none; font-weight: var(--medium);

	&__element {
		display: block; padding: 0; margin: 0; box-shadow: none; appearance: none; outline: none; color: currentColor; background: transparent !important; border: none; cursor: pointer; user-select: none; text-decoration: none;
	}

	&__inner {
		flex: 1 1 auto; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; position: relative; overflow: hidden;

		&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 2; background-color: currentColor; opacity: 0; pointer-events: none; }

		&:after { content: ""; position: absolute; left: 0; top: 0; right: 0; bottom: 0; z-index: 1; box-shadow: inset 0 0 0 1px currentColor; }
	}

	&__element:hover &__inner:before { opacity: 0.05; }

	&__element:focus &__inner:before { opacity: 0.1; }

	&__text { flex: 0 0 auto; }

	&__icon {
		path { fill: currentColor; }
	}

	&__icon-left { flex: 0 0 auto; display: flex; }

	&__icon-right { flex: 0 0 auto; display: flex; }

	&__drop {
		position: absolute; left: 50%; top: 100%; background-color: var(--color-white); border-radius: var(--radius-default); color: var(--color-text); z-index: 10;
		box-shadow: 2px 2px 28px 0 rgba(49, 45, 42, 0.1); pointer-events: none; opacity: 0; transform: translate(-50%, -4px);
		transition: all var(--ui-easing) var(--ui-speed);

		&:before {
			content: ""; width: 0; height: 0; border-style: solid; border-width: 0 10px 10px 10px; border-color: transparent transparent var(--color-white) transparent;
			position: absolute; left: 50%; bottom: 100%; transform: translate(-50%, 1px);
		}

		&.open { display: block; opacity: 1; pointer-events: auto; transform: translate(-50%, 14px); }

		BUTTON {
			display: block; appearance: none; border: none; padding: 0; margin: 0; cursor: pointer; box-shadow: none; background: transparent; color: inherit; width: 100%; overflow: hidden; font-size: 14px; line-height: 24px;

			path { fill: currentColor; }

			&:hover { color: var(--color-link-hover); }
		}

		&__inner { padding: 8px 12px; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; gap: var(--gridunit-tiny); }

		& > LI:first-child &__inner { padding-top: 10px; }

		& > LI:last-child &__inner { padding-bottom: 10px; }
	}

	.loading-content { left: 1px; right: 1px; top: 1px; bottom: 1px; }

	// Intents

	&.theme-default .loading-content { mix-blend-mode: darken; }

	&.theme-default & {
		&__inner { box-shadow: inset 0 0 0 1px currentColor; color: var(--button-bg); }

		&__icon {
			path { fill: currentColor; }
		}
	}

	&.theme-white,
	&.theme-white-transparent,
	&.theme-white-secondary,
	&.theme-primary {
		.loading-content {
			$c1: rgba(#000000, 0.5);
			$c2: var(--color-white);

			background-color: var(--button-bg);

			svg { mix-blend-mode: lighten; width: 16px !important; height: 16px !important; }

			&__gradient { background: conic-gradient(from 270deg at 50% 50%, $c1 0deg, $c1 90deg, $c2 180deg, $c1 190deg, $c1, 270deg, $c1); }

			&__fill { fill: var(--color-black); }

			&__circle { stroke: var(--color-white); }

			&__elem { stroke: var(--color-white); }

			&__group { mix-blend-mode: darken; border: 5px solid red; }
		}
	}

	&.theme-primary & {
		&__inner { background-color: var(--button-bg); color: var(--color-white); }

		&__inner:after { display: none; }
	}

	&.theme-secondary .loading-content { mix-blend-mode: darken; }

	&.theme-secondary & {
		&__inner { color: var(--color-text); }

		&__inner:after { opacity: 0.2; }
	}

	&.theme-transparent .loading-content { mix-blend-mode: darken; }

	&.theme-transparent & {
		&__inner { box-shadow: none; padding: 0 var(--gridunit-small) !important; }

		&__inner:after { display: none; }
	}

	&.theme-white & {
		&__inner { color: var(--color-white); }
	}

	&.theme-white-primary & {
		&__inner { background-color: var(--color-white); color: var(--button-bg); }

		&__inner:after { display: none; }
	}

	&.theme-white-secondary & {
		&__inner { color: var(--color-white); }

		&__inner:after { opacity: 0.2; }
	}

	&.theme-white-transparent & {
		&__inner { padding: 0 16px !important; color: var(--color-white); }

		&__inner:after { display: none; }
	}

	&.theme-link { color: var(--color-link); }

	&.theme-link:hover &__text { color: var(--color-link-hover); text-decoration: underline; }

	&.theme-link & {
        &__inner { padding: 0 !important; }

		&__inner:before,
		&__inner:after { display: none; }
    }

	// Sizes

	&.size-default .loading-content svg { width: 20px * 0.75; height: 20px * 0.75; }

	&.size-default & {
		&__inner { border-radius: calc($h-default / 2); height: $h-default; line-height: $h-default; padding: 0 22px; gap: 10px; font-size: 16px; font-weight: var(--medium); }

		&__inner:after { border-radius: calc($h-default / 2); }

		&__icon { width: 20px; height: 20px; }
	}

	&.size-small .loading-content svg { width: 16px; height: 16px; }

	&.size-small & {
		&__inner { border-radius: calc($h-small / 2); height: $h-small; line-height: $h-small; padding: 0 10px; gap: 6px; font-size: var(--font-size-smallest); font-weight: var(--medium); text-transform: uppercase; }

		&__inner:after { border-radius: calc($h-small / 2); }

		&__icon { width: 16px; height: 16px; }
	}

	&.size-large .loading-content svg { width: 20px; height: 20px; }

	&.size-large & {
		&__inner { border-radius: calc($h-large / 2); height: $h-large; line-height: $h-large; padding: 0 var(--gridunit); gap: 10px; font-size: 16px; font-weight: var(--medium); }

		&__inner:after { border-radius: calc($h-large / 2); }

		&__icon { width: 24px; height: 24px; }
	}

	&.loading &__text,
	&.loading &__icon { opacity: 0; }

	&.disabled,
	&[disabled] {
		cursor: default; opacity: 0.3; pointer-events: none;

		* { cursor: default; }
	}
}
