@import "@/styles/mixins";

.header {
  padding-top: 0; padding-bottom: 0; border-bottom: 1px solid var(--color-neutral-50); margin: 0 !important; flex: 0 0 auto;

  &__row {
    padding-top: var(--gridunit) !important; padding-bottom: var(--gridunit) !important;
    display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--margin-horizontal); align-items: center;
  }

  &__logo {
    flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;

    DIV:first-child { font-size: 36px; line-height: 36px; font-weight: var(--heavy); font-family: var(--font-headings), sans-serif; }

    DIV:last-child { font-size: 12px; line-height: 14px; border-left: 1px solid rgba(0, 0, 0, 0.2); margin-left: 10px; padding-left: 10px; }
  }

  &__menu {
    flex: 1 1 auto;display: flex; flex-direction: row; flex-wrap: nowrap; overflow: hidden; align-items: center; justify-content: center;
    font-weight: var(--bold);

    A {
      text-decoration: none; position: relative; display: block; padding: var(--gridunit-tiny) var(--gridunit-small);

      &:before {
        content: ""; position: absolute; left: var(--gridunit-small); right: var(--gridunit-small); bottom: 0; height: 2px; background-color: currentColor; opacity: 0;
        transition: all var(--ui-speed) var(--ui-easing) 0ms;
      }

      &:hover {
        color: var(--color-link-hover);

        &:before { opacity: 0.5; }
      }

      &[aria-current="true"] {
        color: var(--color-link);

        &:before { opacity: 1; }
      }
    }
  }

  &__user {
    display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; gap: var(--gridunit-small);
  }
}
