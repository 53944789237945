@import "mixins";

TABLE {
  border-collapse: collapse; border-spacing: 0; @include default-margin;
  margin-left: auto; margin-right: auto; white-space: nowrap; width: 100%; font-size: var(--font-size-small);

  TH,
  TD { border: solid black; border-width: 0 0 1px 0; padding: 10px 16px; text-align: left; vertical-align: top; }

  TH {
    font-weight: var(--medium); vertical-align: bottom;
    border-color: var(--color-neutral-700); border-bottom-width: 2px;
  }

  TBODY TH { border-width: 2px 0; background-color: var(--color-neutral-50); }

  TD {
    border-color: var(--color-neutral-300);
  }

  TR:hover { background-color: var(--color-neutral-50); }

  THEAD:has(*[rowspan]):hover,
  TBODY:has(*[rowspan]):hover { background-color: var(--color-neutral-50); }

  A {
    text-decoration: none;

    &:hover { color: var(--color-link-hover); }
  }
}

.widget-title { margin-top: var(--gridunit); font-size: 18px; }

P:not([class]):has(> IMG) {
  display: flex;

  > IMG { width: 100%; height: auto; }
}