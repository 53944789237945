@import "@/styles/mixins";

:import("@/components/ui/Buttons/styles.module.scss") {
  buttons: buttons;
}

:import("@/components/ui/Button/styles.module.scss") {
  button: button;
  button__inner: button__inner;
}

.toast {
  display: grid; grid-template-rows: 0fr; overflow: hidden; transition: all 250ms var(--ui-easing) 0ms; align-items: start; pointer-events: auto; font-size: var(--font-size-smaller);

  &__wrapper { overflow: hidden; }

  &__inner {
    margin: 0 var(--gridunit-small) var(--gridunit-small) var(--gridunit-small); padding: var(--gridunit-small) var(--gridunit-small) var(--gridunit-small) var(--gridunit);
    display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small);
    background-color: var(--color-white); box-shadow: 0 var(--gridunit-tiny) var(--gridunit-small) calc(var(--gridunit-tiny) * -1) rgba(0, 0, 0, 0.3); border-radius: var(--radius-default);
    opacity: 0; transition: all 250ms var(--ui-easing) 0ms; position: relative; overflow: hidden;

    &:before { content: ""; position: absolute; left: 0; top: 0; bottom: 0; width: 8px; background-color: var(--color-focus); opacity: 0.6; }
  }

  &__icon {
    flex: 0 0 auto; overflow: hidden;

    path { fill: currentColor; }
  }

  &__text { flex: 1 1 auto; overflow: hidden; }

  &__close { border-left: 1px solid var(--color-neutral-400); padding-left: var(--gridunit-small); }

  &.is-open { grid-template-rows: 1fr; }

  &.is-open & {
    &__inner { opacity: 1; }
  }

  &.is-closing { grid-template-rows: 0fr; }

  &.is-closing & {
    &__inner { opacity: 0; }
  }

  .buttons { margin: var(--gridunit-tiny) 0 0 0; flex-direction: row; justify-content: flex-end !important; }

  .button__inner,
  .button__inner:after { box-shadow: none !important; border-radius: var(--radius-small) !important; }

  // Intents

  &.intent-default {
    .button__inner { background-color: var(--color-focus); color: var(--color-white) !important; }
  }

  &.intent-default & {
    &__icon { color: var(--color-focus); }

    &__inner:before { background-color: var(--color-focus); }
  }

  &.intent-danger {
    .button__inner { background-color: var(--color-danger); color: var(--color-white) !important; }
  }

  &.intent-danger & {
    &__icon { color: var(--color-danger); }

    &__inner:before { background-color: var(--color-danger); }
  }

  &.intent-wargning {
    .button__inner { background-color: var(--color-warning); color: var(--color-white) !important; }
  }

  &.intent-wargning & {
    &__icon { color: var(--color-warning); }

    &__inner:before { background-color: var(--color-warning); }
  }

  &.intent-success {
    .button__inner { background-color: var(--color-success); color: var(--color-white) !important; }
  }

  &.intent-success & {
    &__icon { color: var(--color-success); }

    &__inner:before { background-color: var(--color-success); }
  }
}