@import 'mixins';

/* stylelint-disable rule-empty-line-before  */

@-ms-viewport {
	width: device-width;
}

* { scrollbar-width: thin; -webkit-tap-highlight-color: transparent; }

HTML, BODY { padding: 0; margin: 0; -webkit-overflow-scrolling: touch; }

HTML { overflow: -moz-scrollbars-vertical; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }
BODY { -ms-interpolation-mode: nearest-neighbor; position: relative; }

ARTICLE, ASIDE, FOOTER, HEADER, NAV, SECTION, FIGCAPTION, FIGURE, MAIN { display: block; }

HR { box-sizing: content-box; height: 0; overflow: visible; }

PRE, CODE, KBD, SAMP { font-family: monospace; font-size: 1em; }

svg {
	opacity: 0.99;
	shape-rendering: geometricPrecision;

	circle[fill="none"],

	path[fill="none"] { fill: transparent !important; }

	&:not(:root) { overflow: hidden; }
}

A { -webkit-text-decoration-skip: objects; }
B { font-weight: var(--bold); }

AUDIO, VIDEO { display: inline-block; }
AUDIO:not([controls]) { display: none; height: 0; }
IMG { border-style: none; }

BUTTON, INPUT, OPTGROUP, SELECT, TEXTAREA, OPTION, LABEL { font-size: inherit; font-family: inherit; line-height: inherit; margin: 0; border-radius: 0; box-sizing: border-box; max-width: 100%; text-transform: inherit; font-weight: inherit; }
BUTTON, INPUT { overflow: visible; }
BUTTON, SELECT { text-transform: none; }
BUTTON, HTML [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; outline: none; }
BUTTON::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { border-style: none; padding: 0; }
BUTTON:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring { outline: 1px dotted ButtonText; }
FIELDSET { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
LEGEND { box-sizing: border-box; color: inherit; display: table; max-width: 100%; padding: 0; white-space: normal; }
PROGRESS { display: inline-block; vertical-align: baseline; }
TEXTAREA { overflow: auto; }

[type="checkbox"],
[type="radio"] { box-sizing: border-box; padding: 0; }

[type="search"] { -webkit-appearance: textfield; outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

[type="number"] { -moz-appearance: textfield; }

[type="number"]::-webkit-outer-spin-button,
[type="number"]::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none; margin: 0; height: auto;
}

::-webkit-file-upload-button { -webkit-appearance: button; font: inherit; }
DETAILS, MENU { display: block; }
SUMMARY { display: list-item; }
CANVAS { display: inline-block; }
TEMPLATE { display: none; }
[hidden] { display: none; }

P, .p { padding: 0; margin: 1em 0 0 0; }

@include mobile {
	INPUT,
	SELECT,
	TEXTAREA,
	SELECT:focus,
	TEXTAREA:focus,
	INPUT:focus { font-size: 16px !important; }
}

INPUT:-webkit-autofill,
INPUT:-webkit-autofill:hover,
INPUT:-webkit-autofill:focus,
INPUT:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}
