@import 'mixins';

/* stylelint-disable rule-empty-line-before  */

.align-center { text-align: center !important; }
.align-right { text-align: right !important; }
.align-left { text-align: left !important; }

.nowrap { white-space: nowrap !important; }
.nomargin { margin: 0; }
.bold { font-weight: var(--bold) !important; }
.medium { font-weight: var(--medium) !important; }
.blur { filter: blur(5px); }
.hidden { display: none !important; }

.text-intent-danger { color: var(--color-danger); }
.text-intent-success { color: var(--color-success); }
.text-intent-warning { color: var(--color-warning); }

.text-info-primary {
	font-size: 18px; font-weight: var(--medium);
	B, STRONG { font-size: 24px; font-weight: var(--medium); }
}
.text-info-primary + .text-info-secondary { margin-top: var(--gridunit-tiny); }
.text-info-secondary { font-size: 14px; color: var(--color-neutral-800); }

.text-size-smaller { font-size: 12px; }
.text-size-small { font-size: 14px; }
.text-size-large { font-size: 24px; }
.text-size-larger { font-size: 32px; }

.font-headings { font-family: var(--font-headings), sans-serif; }

.margin-top-small { margin-top: var(--gridunit-small); }
.margin-top-medium { margin-top: var(--gridunit); }
.margin-top-large { margin-top: var(--gridunit-large); }

.clear { @include clearfix; }
.f-right { float: right; }
.f-left { float: left; }

@include mobile { .mobile-hide { display: none !important; } }
@include not-mobile { .not-mobile-hide { display: none !important; } }
@include tablet { .tablet-hide { display: none !important; } }
@include desktop { .desktop-hide { display: none !important; } }
@include not-desktop { .not-desktop-hide { display: none !important; } }

// Widths

.wauto { width: auto !important; flex: 0 0 auto; }

.w1p { width: 1%; }
.w10p { width: 10%; }
.w20p { width: 20%; }
.w25p { width: 25%; }
.w30p { width: 30%; }
.w40p { width: 40%; }
.w50p { width: 50%; }
.w60p { width: 60%; }
.w70p { width: 70%; }
.w75p { width: 75%; }
.w80p { width: 80%; }
.w90p { width: 90%; }
.w100p { width: 100%; }

.w100 { width: 100px; }
.w150 { width: 150px; }
.w200 { width: 200px; }
.w250 { width: 250px; }
.w300 { width: 300px; }

@include mobile {
	.mobile-w1p { width: 1%; }
	.mobile-w10p { width: 10%; }
	.mobile-w20p { width: 20%; }
	.mobile-w25p { width: 25%; }
	.mobile-w30p { width: 30%; }
	.mobile-w40p { width: 40%; }
	.mobile-w50p { width: 50%; }
	.mobile-w60p { width: 60%; }
	.mobile-w70p { width: 70%; }
	.mobile-w75p { width: 75%; }
	.mobile-w80p { width: 80%; }
	.mobile-w90p { width: 90%; }
	.mobile-w100p { width: 100%; }

	.mobile-w100 { width: 100px !important; }
	.mobile-w200 { width: 200px !important; }
	.mobile-w300 { width: 300px !important; }
}
@include desktop {
	.desktop-w1p { width: 1%; }
	.desktop-w10p { width: 10%; }
	.desktop-w15p { width: 15%; }
	.desktop-w20p { width: 20%; }
	.desktop-w25p { width: 25%; }
	.desktop-w30p { width: 30%; }
	.desktop-w40p { width: 40%; }
	.desktop-w50p { width: 50%; }
	.desktop-w60p { width: 60%; }
	.desktop-w70p { width: 70%; }
	.desktop-w75p { width: 75%; }
	.desktop-w80p { width: 80%; }
	.desktop-w90p { width: 90%; }
	.desktop-w100p { width: 100%; }

	.desktop-w100 { width: 100px; }
	.desktop-w200 { width: 200px; }
	.desktop-w300 { width: 300px; }
}
