:import("@/components/ui/LoadingContent/styles.module.scss") {
	loading-content: loading-content;
	loading-content__gradient: loading-content__gradient;
	loading-content__fill: loading-content__fill;
	loading-content__circle: loading-content__circle;
	loading-content__elem: loading-content__elem;
	loading-content__group: loading-content__group;
}

.button-icon {
	$h-default: 40px;
	$h-tiny: 24px;
	$h-small: 28px;
	$h-medium: 32px;
	$h-large: 56px;

	display: inline-block; border: none; cursor: pointer; text-decoration: none; text-align: center; margin: 0; background-color: transparent;
	white-space: nowrap; position: relative; transition: all var(--ui-speed) var(--ui-easing) 0ms; box-sizing: border-box; user-select: none;

	&__element {
		display: block; padding: 0; margin: 0; box-shadow: none; appearance: none; outline: none;
		color: currentColor; background: transparent !important; border: none; cursor: pointer; user-select: none;
	}

	&__element:hover &__inner:before { opacity: 0.05 !important; }

	&__element:focus &__inner:before { opacity: 0.1 !important; }

	&__inner {
		flex: 1 1 auto; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; position: relative; overflow: hidden;

		&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: 2; background-color: currentColor; opacity: 0; pointer-events: none; }

		&:hover:before { opacity: 0.05; }

		&:after { content: ""; position: absolute; left: 0; top: 0; right: 0; bottom: 0; z-index: 1; box-shadow: inset 0 0 0 1px currentColor; }
	}

	&__text { flex: 0 0 auto; }

	&__icon {
		flex: 0 0 auto; display: flex;

		path,
		circle { fill: currentColor; }
	}

	&__notifications {
		position: absolute; right: 0; top: 0; box-sizing: border-box;
		background-color: var(--color-danger); color: var(--color-white); border-radius: 7px; width: 14px; height: 14px; line-height: 14px; text-align: center; min-width: 16px; padding: 0 2px;
		font-weight: var(--medium); font-size: 10px;
	}

	.loading-content { left: 0; right: 0; top: 0; bottom: 0; height: auto !important; }

	// Intents

	&.theme-default .loading-content { mix-blend-mode: darken; }
	
	&.theme-default & {
		&__inner { color: var(--button-bg); }
	
		&__icon {
			path,
			circle { fill: currentColor; }
		}
	}

	&.theme-white,
	&.theme-white-transparent,
	&.theme-white-secondary,
	&.theme-primary {
		.loading-content {
			$c1: rgba(#000000, 0.5);
			$c2: var(--color-white);

			background-color: var(--button-bg);

			svg { mix-blend-mode: lighten; }

			&__gradient { background: conic-gradient(from 270deg at 50% 50%, $c1 0deg, $c1 90deg, $c2 180deg, $c1 190deg, $c1, 270deg, $c1); }

			&__fill { fill: var(--color-black); }

			&__circle { stroke: var(--color-white); }

			&__elem { stroke: var(--color-white); }

			&__group { mix-blend-mode: darken; border: 5px solid red; }
		}
	}

	&.theme-primary & {
		&__inner { background-color: var(--button-bg); color: var(--color-white); }

		&__inner:after { display: none; }
	}

	&.theme-secondary .loading-content { mix-blend-mode: darken; }

	&.theme-secondary & {
		&__inner { color: var(--color-text); }

		&__inner:after { opacity: 0.2; }
	}

	&.theme-transparent .loading-content { mix-blend-mode: darken; }

	&.theme-transparent & {
		&__inner { box-shadow: none; border-radius: 0 !important; }

		&__inner:before,
		&__inner:after { opacity: 0 !important; }

		&__element:hover,
		&__element:focus { color: var(--button-bg); }
	}

	&.theme-white & {
		&__inner { color: var(--color-white); }
	}

	&.theme-white-primary & {
		&__inner { background-color: var(--color-white); color: var(--button-bg); }

		&__inner:after { display: none; }
	}

	&.theme-white-secondary & {
		&__inner { color: var(--color-white); }

		&__inner:after { opacity: 0.2; }
	}

	&.theme-white-transparent & {
		&__inner { box-shadow: none; color: var(--color-white); border-radius: 0 !important; }

		&__inner:before,
		&__inner:after { opacity: 0; }
	}

	// Sizes

	&.size-default .loading-content svg { width: 24px * 0.75; height: 24px * 0.75; }

	&.size-default & {
		&__inner { border-radius: calc($h-default / 2); width: $h-default; height: $h-default; }

		&__inner:before,
		&__inner:after { border-radius: calc($h-default / 2); }

		&__icon { width: 24px; height: 24px; }
	}

	&.size-default.theme-transparent &__icon,
	&.size-default.theme-white-transparent &__icon { width: $h-default; height: $h-default; }

	&.size-default.theme-transparent .loading-content svg,
	&.size-default.theme-white-transparent .loading-content svg { width: $h-default * 0.75; height: $h-default * 0.75; }

	&.size-tiny .loading-content svg { width: 16px * 0.75; height: 16px * 0.75; }

	&.size-tiny & {
		&__inner { border-radius: calc($h-tiny / 2); width: $h-tiny; height: $h-tiny; }

		&__inner:before,
		&__inner:after { border-radius: calc($h-tiny / 2); }

		&__icon { width: 16px; height: 16px; }
	}

	&.size-tiny.theme-transparent &__icon,
	&.size-tiny.theme-white-transparent &__icon { width: $h-tiny; height: $h-tiny; }

	&.size-tiny.theme-transparent .loading-content svg,
	&.size-tiny.theme-white-transparent .loading-content svg { width: $h-tiny * 0.75; height: $h-tiny * 0.75; }

	&.size-small .loading-content svg { width: 16px * 0.75; height: 16px * 0.75; }

	&.size-small & {
		&__inner { border-radius: calc($h-small / 2); width: $h-small; height: $h-small; }

		&__inner:before,
		&__inner:after { border-radius: calc($h-small / 2); }

		&__icon { width: 16px; height: 16px; }
	}

	&.size-small.theme-transparent &__icon,
	&.size-small.theme-white-transparent &__icon { width: $h-small; height: $h-small; }

	&.size-small.theme-transparent .loading-content svg,
	&.size-small.theme-white-transparent .loading-content svg { width: $h-small * 0.75; height: $h-small * 0.75; }

	&.size-medium .loading-content svg { width: 16px * 0.75; height: 16px * 0.75; }

	&.size-medium & {
		&__inner { border-radius: calc($h-medium / 2); width: $h-medium; height: $h-medium; }

		&__inner:before,
		&__inner:after { border-radius: calc($h-medium / 2); }

		&__icon { width: 16px; height: 16px; }
	}

	&.size-medium.theme-transparent &__icon,
	&.size-medium.theme-white-transparent &__icon { width: $h-medium; height: $h-medium; }

	&.size-medium.theme-transparent .loading-content svg,
	&.size-medium.theme-white-transparent .loading-content svg { width: $h-medium * 0.75; height: $h-medium * 0.75; }

	&.size-large .loading-content svg { width: 32px * 0.75; height: 32px * 0.75; }

	&.size-large & {
		&__inner { border-radius: calc($h-large / 2); width: $h-large; height: $h-large; }

		&__inner:after { border-radius: calc($h-large / 2); }

		&__icon { width: 32px; height: 32px; }
	}

	&.size-large.theme-transparent &__icon,
	&.size-large.theme-white-transparent &__icon { width: $h-large; height: $h-large; }

	&.size-large.theme-transparent .loading-content svg,
	&.size-large.theme-white-transparent .loading-content svg { width: $h-large * 0.75; height: $h-large * 0.75; }

	&.loading &__icon { opacity: 0; }
}
