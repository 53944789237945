@import "@/styles/mixins";

.layout-overlay {
	flex: 0 1 100%; display: flex; flex-direction: column; flex-wrap: nowrap; box-sizing: border-box; overflow: hidden; max-width: 100vw;

	&__inner { flex: 0 1 100%; display: flex; flex-direction: column; flex-wrap: nowrap; box-sizing: border-box; overflow: hidden; }

	&__content {
		position: relative; z-index: 0; background-color: var(--color-white); max-width: 100vw; display: flex; flex-direction: column; flex: 1 1 auto !important; box-sizing: border-box;
		@include overflow(false);

		> *:first-child { flex-grow: 1; }
	}

	@include not-mobile {
		&.size-default { flex-direction: row; align-items: center; justify-content: center; flex: 1 0 auto; }

		&.size-default &__inner { flex: 0 0 800px; }

		&.size-default &__content { overflow: hidden; border-radius: var(--radius-large); }

		&.size-small { flex-direction: row; align-items: center; justify-content: center; flex: 1 0 auto; }

		&.size-small &__inner { flex: 0 0 600px; }

		&.size-small &__content { overflow: hidden; border-radius: var(--radius-large); }

		&.size-medium { flex-direction: row; align-items: center; justify-content: center; flex: 1 0 auto; }

		&.size-medium &__inner { flex: 0 0 800px; }

		&.size-medium &__content { overflow: hidden; border-radius: var(--radius-large); }

		&.size-wide { flex-direction: row; align-items: center; justify-content: center; flex: 1 0 auto; }

		&.size-wide &__inner { flex: 0 0 1000px; }

		&.size-wide &__content { overflow: hidden; border-radius: var(--radius-large); }

		&.size-max { flex-direction: row; align-items: center; justify-content: center; flex: 1 0 auto; padding: 0; }

		&.size-max &__inner { flex: 0 0 100%; }

		&.size-max &__content { overflow: hidden; }

		&.size-mobilemenu { flex-direction: row; align-items: stretch; justify-content: flex-start; flex: 1 0 auto; padding: 0; }

		&.size-mobilemenu &__inner { flex: 0 0 360px; }

		&.size-mobilemenu &__content { overflow: hidden; }
	}

	@include mobile {
		&.size-mobilemenu { flex-direction: row; align-items: stretch; justify-content: flex-start; flex: 1 0 auto; }

		&.size-mobilemenu &__inner { flex: 0 0 100vw; }

		&.size-mobilemenu &__content { overflow: hidden; }
	}
}
