@import 'mixins';

/* stylelint-disable rule-empty-line-before  */

HTML, BODY { font-family: var(--font-base), Arial, Helvetica, sans-serif; font-size: var(--font-size-default); line-height: var(--line-height-default); }
BODY { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

H1, H2, H3, H4, H5, H6 {
  display: block; line-height: var(--line-height-headings); margin: 0; @include default-margin; flex: 0 0 auto !important; font-family: var(--font-headings), Arial, Helvetica, sans-serif; font-weight: var(--heavy); text-wrap: balance;
}

H1 { font-size: var(--font-size-largest); }

H2 { font-size: var(--font-size-larger); }

H3 { font-size: var(--font-size-large); }
