@import "@/styles/mixins";

:import("./../Button/styles.module.scss") {
	button: button;
	button__element: button__element;
}

.heading-with-tools {
  @include default-margin;

  display: grid; grid-template-areas: "title buttons" "description description"; grid-template-columns: 1fr auto; grid-auto-rows: auto;

  H1, H2, H3, H4, H5, H6 { margin: 0; grid-area: title; }

  &__description {
    grid-area: description;

    P:first-child { margin: 0; }
  }

  &__buttons {
    grid-area: buttons; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-end; gap: var(--gridunit-tiny); margin-left: auto;
  }

  @include mobile {
    grid-template-areas: "title buttons" "description description"; align-items: start; row-gap: var(--gridunit-tiny);
  }

  // Special cases

  @include mobile {
    &.has-buttons {
      grid-template-areas: "title" "description" "buttons"; grid-template-columns: 1fr;
    }

    &.has-buttons & {
      &__buttons {
        margin: 0; display: flex; flex-direction: row;

        .button {
            display: block; flex: 1 1 auto;

            &__element { width: 100%; }
        }
      }
    }
  }
}
